import { eventsMock } from '../mocks/events-mock'
import Api from '../services/api-service'
import { EventGetAllResponse } from '../types/event-requests'

const eventRepository = {
  async getAll(): Promise<EventGetAllResponse> {
    const response = await Api(`/api/pos/events`).get()
    return response.data
    // return await Promise.resolve(eventsMock)
  }
}

export default eventRepository
