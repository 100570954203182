import { createNativeStackNavigator } from '@react-navigation/native-stack'
import AuthRoutes from './auth'
import AppRoutes from './app'

export type RootStackParamList = {
  auth: undefined
  app: undefined
}

const RootStack = createNativeStackNavigator<RootStackParamList>()
export default function Routes() {
  return (
    <RootStack.Navigator screenOptions={{ headerShown: false, gestureEnabled: false }}>
      <RootStack.Screen name="auth" component={AuthRoutes} />
      <RootStack.Screen name="app" component={AppRoutes} />
    </RootStack.Navigator>
  )
}
