import { useState } from 'react'
import setByString from '../helpers/set-by-string'

type ReturnType<T> = [reg: T, updateReg: (value: any, path: string) => T, updateAll: (data: T) => void]

export default function useReg<T = any>(defaultValue: T): ReturnType<T> {
  const [reg, updateAll] = useState<T>(defaultValue)

  function updateReg(value: object, path: string): T {
    const updatedData = setByString(reg, path, value)
    updateAll({ ...reg, ...updatedData })
    return updatedData
  }
  return [reg, updateReg, updateAll]
}
