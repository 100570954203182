import { createNativeStackNavigator, NativeStackScreenProps } from '@react-navigation/native-stack'
import { RootStackParamList } from '.'
import LoginScreen from '../components/screens/auth/login-screen'

export type AuthStackParamList = {
  login: undefined
}

type Props = NativeStackScreenProps<RootStackParamList, 'auth'>

const AuthStack = createNativeStackNavigator<AuthStackParamList>()
export default function AuthRoutes(props: Props) {
  return (
    <AuthStack.Navigator initialRouteName="login" screenOptions={{ headerShown: false }}>
      <AuthStack.Screen name="login" component={LoginScreen} />
    </AuthStack.Navigator>
  )
}
