import AsyncStorage from '@react-native-async-storage/async-storage'

async function storeData(key: string, value: string | null): Promise<void> {
  if (value == null) AsyncStorage.removeItem(key)
  else AsyncStorage.setItem(key, value)
}
async function getData(key: string): Promise<string | null> {
  return await AsyncStorage.getItem(key)
}

export const StorageService = {
  async setBearerToken(value: string | null) {
    await storeData('tktr_pos_token', value)
  },
  async getBearerToken(): Promise<string | null> {
    return await getData('tktr_pos_token')
  },
  async setCartId(value: string | null) {
    await storeData('tktr_pos_cart_id', value)
  },
  async getCartId(): Promise<string | null> {
    return await getData('tktr_pos_cart_id')
  }
}
