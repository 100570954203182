import { StyleSheet, View, ViewProps, ViewStyle } from 'react-native'

interface Props extends ViewProps {
  cols?: number
  auto?: boolean
  gap?: number | 'auto'
}

export default function Col(props: Props) {
  const gapValue = Number(props.gap) * 0.5
  const gapStyle = props.gap ? { paddingHorizontal: gapValue, paddingVertical: gapValue } : null
  const isAuto = (typeof props.cols == 'string' && props.cols == 'auto') || props.auto === true
  function getColsStyle(): ViewStyle {
    const colsStyle: Record<string, ViewStyle> = {}
    for (let index = 1; index <= 12; index++) {
      colsStyle[`col${index}`] = {
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: `${100 / (12 / index)}%`,
        maxWidth: `${100 / (12 / index)}%`
      }
    }

    switch (isAuto) {
      case false:
        return colsStyle[`col${props.cols}`]
      default:
        return styles.auto
    }
  }
  return (
    <View style={[styles.col, getColsStyle(), gapStyle]} {...props}>
      {props.children}
    </View>
  )
}

const styles = StyleSheet.create({
  col: {
    flexBasis: 0,
    flexGrow: 1,
    maxWidth: '100%'
  },
  auto: {
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: 'auto',
    width: 'auto',
    maxWidth: '100%'
  }
})
