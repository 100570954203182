import { useEffect, useState } from 'react'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { AppStackParamList } from '../../../routes/app'
import Layout from '../../templates/layout'
import Stack from '../../ui/stack'
import CartItem from '../../compositions/cart-item'
import Total from '../../compositions/total'
import Button from '../../ui/button'
import { StorageService } from '../../../services/storage-service'
import cartRepository from '../../../repositories/cart-repository'
import { Cart, CartItem as CartItemType } from '../../../types/cart'
import { CartTicket } from '../../../types/cart-requests'

type Props = NativeStackScreenProps<AppStackParamList, 'cart'>
export type CartItemWithAmount = CartItemType & { amount: number }

export default function CartScreen(props: Props) {
  const [cartItems, setCartItems] = useState<CartItemWithAmount[]>([])
  const [cartId, setCartId] = useState<string | null>(null)

  function getSum(): number {
    return cartItems.reduce((sum, item) => (sum += item.price * item.amount), 0)
  }
  function organizeCartItens(cartData: Cart): void {
    if (!cartData) return
    const items = cartData.items.reduce((items, cartItem) => {
      let canBeAdded = true
      let amount = 1
      if (items.length <= 0) canBeAdded = true
      items.forEach(item => {
        const alreadyHas =
          cartItem.combination.id == item.combination.id &&
          cartItem.date_time.id == item.date_time.id &&
          cartItem.ticket_type == item.ticket_type
        if (alreadyHas) {
          canBeAdded = false
          item.amount += 1
        }
      })
      if (canBeAdded) return [...items, { ...cartItem, amount: amount }]
      return items
    }, [] as CartItemWithAmount[])
    setCartItems(items)
  }
  async function updateCartItemAmount(item: CartItemWithAmount, amount: number): Promise<void> {
    if (amount > item.amount) {
      await addToCart(item)
    } else {
      await removeFromCart(item)
    }
    await fetchCartItems()
  }
  async function addToCart(ticket: CartItemWithAmount): Promise<void> {
    const ticketToAdd: CartTicket[] = [
      {
        combination_id: ticket.combination.id,
        date_id: ticket.date_time.id,
        product_id: ticket.product.id,
        quantity: 1,
        ticket_type: ticket.ticket_type == 'half' ? 'half' : 'normal'
      }
    ]
    const savedCartId = await StorageService.getCartId()
    const cartId = await cartRepository.add(ticketToAdd, savedCartId)
    await StorageService.setCartId(cartId)
  }
  async function removeFromCart(ticket: CartItemWithAmount): Promise<void> {
    const savedCartId = await StorageService.getCartId()
    if (!savedCartId) return
    await cartRepository.remove(savedCartId, ticket.id)
  }
  function goToEvents(): void {
    props.navigation.navigate('events')
  }
  async function goToPayment(): Promise<void> {
    props.navigation.navigate('payment')
  }
  function hasCartItems(): boolean {
    return Boolean(cartItems && cartItems.length)
  }

  async function fetchCartItems() {
    const cartId = await StorageService.getCartId()
    setCartId(cartId)
    if (!cartId) return
    const cart = await cartRepository.get(cartId)
    organizeCartItens(cart)
  }

  useEffect(() => {
    async function loadCartItems() {
      await fetchCartItems()
    }
    loadCartItems()
  }, [])

  return (
    <Layout title="Carrinho">
      <Stack gap={64}>
        <Stack gap={8}>
          <Stack gap={8}>
            {hasCartItems()
              ? cartItems.map((item, index) => (
                  <CartItem key={index} cartItem={item} setAmount={amount => updateCartItemAmount(item, amount)} />
                ))
              : null}
          </Stack>
        </Stack>
        <Stack gap={8}>
          <Total value={getSum()} />
          <Button label="Continuar comprando" size="lg" variant="muted" onPress={goToEvents} />
          <Button label="Ir para o pagamento" size="lg" onPress={goToPayment} />
        </Stack>
      </Stack>
    </Layout>
  )
}
