import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { useEffect, useState } from 'react'
import eventRepository from '../../../repositories/event-repository'
import { AppStackParamList } from '../../../routes/app'
import { Event } from '../../../types/event'
import EventCard from '../../compositions/event-card'
import Layout from '../../templates/layout'
import Stack from '../../ui/stack'

type Props = NativeStackScreenProps<AppStackParamList, 'events'>

export default function EventsScreen(props: Props) {
  const [events, setEvents] = useState<Event[]>([])

  function goToEventProducts(eventId: string): void {
    props.navigation.navigate('product_list', { eventId })
  }

  useEffect(() => {
    async function fetchEvents() {
      try {
        const fetchedEvents = await eventRepository.getAll()
        setEvents(fetchedEvents)
      } catch (error) {
        console.log('error', error)
      }
    }
    fetchEvents()
  }, [])
  return (
    <Layout title="Eventos disponíveis">
      <Stack gap={16}>
        {events.map(event => (
          <EventCard key={event.id} event={event} onPress={() => goToEventProducts(event.id)} />
        ))}
      </Stack>
    </Layout>
  )
}
