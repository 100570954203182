import { ReactNode } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import colors from '../../constants/colors'

interface Props {
  title: string
  children: ReactNode
}

export default function ProductDetailSection(props: Props) {
  return (
    <View>
      <Text style={styles.title}>{props.title}</Text>
      {props.children}
    </View>
  )
}

const styles = StyleSheet.create({
  title: {
    color: colors.primary,
    marginBottom: 8,
    fontWeight: 'bold',
    fontSize: 16
  }
})
