import Api from '../services/api-service'
import { UserGetResponse } from '../types/user-requests'

const UserRepository = {
  async get(): Promise<UserGetResponse> {
    const response = await Api(`/api/pos/user`).get()
    return response.data
    // return await Promise.resolve({ user_type: 'pos' })
  }
}

export default UserRepository
