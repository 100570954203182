import { Image, StyleSheet, Text, TouchableHighlight, TouchableHighlightProps, View } from 'react-native'
import colors from '../../constants/colors'
import { Event } from '../../types/event'

interface Props extends TouchableHighlightProps {
  event: Event
}

export default function EventCard(props: Props) {
  return (
    <TouchableHighlight style={styles.card} {...props}>
      <>
        <Image style={styles.image} source={{ uri: props.event.image }} />
        <View style={styles.titleWrapper}>
          <Text style={styles.title}>{props.event.name}</Text>
        </View>
      </>
    </TouchableHighlight>
  )
}

const styles = StyleSheet.create({
  card: {
    borderRadius: 8,
    height: 128,
    backgroundColor: '#cccccc',
    position: 'relative'
  },
  image: {
    borderRadius: 8,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  },
  titleWrapper: {
    backgroundColor: colors.primary,
    borderBottomEndRadius: 8,
    borderTopEndRadius: 8,
    minHeight: 36,
    display: 'flex',
    justifyContent: 'center',
    paddingHorizontal: 24,
    position: 'absolute',
    top: 8,
    left: -8
  },
  title: {
    color: '#ffffff',
    fontSize: 18,
    fontWeight: 'bold'
  }
})
