import { Image, StyleSheet, Text, View } from 'react-native'
import colors from '../../constants/colors'
import currency from '../../filters/currency'
import { CartItemWithAmount } from '../screens/app/cart-screen'
import AmountSelector from '../ui/amount-selector'

interface Props {
  cartItem: CartItemWithAmount
  setAmount(amount: number): void
}

export default function CartItem(props: Props) {
  function getTitle(): string {
    const isParking = props.cartItem.type == 'parking'
    const ticketType = props.cartItem.ticket_type == 'normal' ? 'Inteira' : 'Meia'
    const names = props.cartItem.combination.options.reduce((string, item) => string + ' - ' + item.name, '')
    if (isParking) return `Estacionamento${names}`
    return `${ticketType}${names}`
  }
  function getDate(): string {
    return props.cartItem.date_time.date + ' - ' + props.cartItem.date_time.time
  }
  return (
    <View style={styles.wrapper}>
      <Image style={styles.image} source={{ uri: props.cartItem.product.image.sm }} />
      <View style={styles.info}>
        <Text style={styles.title}>{getTitle()}</Text>
        <Text style={styles.subtitle}>{props.cartItem.product.name}</Text>
        <Text style={styles.date}>{getDate()}</Text>
        <Text style={styles.price}>{currency(props.cartItem.price)}</Text>
        <View style={styles.amount}>
          <AmountSelector amount={props.cartItem.amount} setAmount={props.setAmount} />
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    gap: 8
  },
  image: {
    width: 96,
    height: 128,
    borderRadius: 8
  },
  info: {
    display: 'flex',
    gap: 4,
    flex: 1,
    position: 'relative'
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold'
  },
  subtitle: {
    fontSize: 14
  },
  date: {
    fontSize: 14
  },
  price: {
    fontSize: 14,
    color: colors.primary,
    fontWeight: 'bold'
  },
  amount: {
    position: 'absolute',
    bottom: 0,
    right: 0
  }
})
