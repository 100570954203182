import { StyleSheet, Text, TouchableOpacity } from 'react-native'
import colors from '../../constants/colors'
import Icon from './icon'

interface Props {
  label: string
  selected: any
  value: any
  setSelected: (value: any) => void
}

export default function Radio(props: Props) {
  const isSelected = () => props.selected == props.value
  return (
    <TouchableOpacity
      style={[styles.radio, isSelected() ? styles.radioSelected : null]}
      onPress={() => props.setSelected(props.value)}>
      <Icon
        name={isSelected() ? 'radio-button-checked' : 'radio-button-unchecked'}
        color={isSelected() ? '#ffffff' : '#000000'}
        size={24}
      />
      <Text style={[isSelected() ? styles.selectedText : null]}>{props.label}</Text>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  radio: {
    backgroundColor: '#eeeeee',
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
    minHeight: 64,
    alignItems: 'center',
    paddingHorizontal: 24,
    borderRadius: 8
  },
  radioSelected: {
    backgroundColor: colors.primary
  },
  selectedText: {
    color: '#ffffff'
  }
})
