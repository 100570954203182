import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '.'
import { CartItem } from '../types/cart-item'

interface CartState {
  data: CartItem[]
}

export const slice = createSlice({
  name: 'cart',
  initialState: <CartState>{
    data: []
  },
  reducers: {
    addToCart(state: CartState, action: PayloadAction<CartItem>): CartState {
      return { ...state, data: [...state.data, action.payload] }
    },
    updateAmount(state: CartState, action: PayloadAction<{ index: number; amount: number }>): CartState {
      const amount = action.payload.amount
      if (amount == 0) {
        const updatedCartItems = state.data.filter((item, index) => index !== action.payload.index)
        return { ...state, data: updatedCartItems }
      }
      const updatedCartItems = state.data.map((item, itemIndex) => {
        if (itemIndex === action.payload.index) {
          return { ...item, amount: amount }
        }
        return item
      })
      return { ...state, data: updatedCartItems }
    }
  }
})

export const selectCart = createSelector(
  (state: RootState) => state.cart,
  counter => counter.data
)

export const { addToCart, updateAmount } = slice.actions
export default slice.reducer
