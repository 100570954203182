import { ReactNode } from 'react'
import { ActivityIndicator, Image, ScrollView, StyleSheet, Text, View } from 'react-native'
import { useSelector } from 'react-redux'
import colors from '../../constants/colors'
import { selectLoading } from '../../stores/loading'

interface Props {
  children: ReactNode
  title?: string
  bannerImage?: string
}

export default function Layout(props: Props) {
  const isLoading = useSelector(selectLoading)
  return isLoading ? (
    <View style={styles.loading}>
      <ActivityIndicator size="large" color={colors.primary} />
    </View>
  ) : (
    <>
      <ScrollView contentContainerStyle={styles.scroll} showsVerticalScrollIndicator={false}>
        {props.bannerImage && <Image style={styles.banner} source={{ uri: props.bannerImage }} />}
        <View style={styles.content}>
          <View style={styles.header}>
            <Text style={styles.title}>{props.title}</Text>
          </View>
          {props.children}
        </View>
      </ScrollView>
    </>
  )
}

const styles = StyleSheet.create({
  loading: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  header: {
    marginTop: 64,
    marginBottom: 32
  },
  title: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 24
  },
  scroll: {
    width: '100%',
    maxWidth: 544,
    marginHorizontal: 'auto',
    paddingBottom: 32
  },
  content: {
    paddingHorizontal: 16
  },
  banner: {
    width: '100%',
    height: 196
  }
})
