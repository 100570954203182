import { Dispatch, ReactNode, SetStateAction } from 'react'
import { Modal as NativeModal, ScrollView, StyleSheet, Text, TouchableHighlight, View } from 'react-native'
import { SafeAreaProvider, SafeAreaView } from 'react-native-safe-area-context'
import colors from '../../constants/colors'
import Icon from '../ui/icon'

interface Props {
  isOpened: boolean
  setIsOpened: Dispatch<SetStateAction<boolean>>
  fullHeight?: boolean
  title?: string
  children?: ReactNode
}

export default function Modal(props: Props) {
  function closeSelectionModal(): void {
    props.setIsOpened(false)
  }

  return (
    <NativeModal
      animationType={props.fullHeight ? 'slide' : 'fade'}
      transparent={true}
      visible={props.isOpened}
      onRequestClose={closeSelectionModal}>
      <SafeAreaProvider>
        <SafeAreaView style={[styles.overlay, props.fullHeight ? styles.overlayFull : null]}>
          <View style={[styles.modal, props.fullHeight ? styles.modalFull : null]}>
            <View style={[styles.header, styles.content]}>
              <TouchableHighlight style={styles.closeButton} onPress={closeSelectionModal}>
                <Icon name="close" size={24} color="#ffffff" />
              </TouchableHighlight>
              {props.title && <Text style={styles.title}>{props.title}</Text>}
            </View>
            <ScrollView showsVerticalScrollIndicator={false} style={styles.content}>
              {props.children}
            </ScrollView>
          </View>
        </SafeAreaView>
      </SafeAreaProvider>
    </NativeModal>
  )
}

const styles = StyleSheet.create({
  overlay: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.7)'
  },
  overlayFull: {
    justifyContent: 'flex-end',
    backgroundColor: 'transparent'
  },
  modal: {
    backgroundColor: colors.background,
    borderRadius: 8,
    elevation: 5,
    width: '100%',
    maxHeight: '100%',
    padding: 16,
    maxWidth: 544,
    marginHorizontal: 'auto'
  },
  modalFull: {
    height: '100%'
  },
  header: {
    marginBottom: 32,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 16
  },
  title: {
    fontSize: 22,
    fontWeight: 'bold'
  },
  closeButton: {
    backgroundColor: colors.secondary,
    height: 48,
    width: 48,
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  content: {
    width: '100%',
    maxWidth: 544,
    marginHorizontal: 'auto'
  }
})
