import axios from 'axios'
import { rootNavigate } from '../helpers/root-navigation'
import store from '../stores'
import { setIsLoading } from '../stores/loading'
import { StorageService } from './storage-service'

let requestsCount = 0
let loadingTimer: any = null
const baseUrl = 'https://dash.tktr.com.br'
const xhr = axios.create({ baseURL: baseUrl, timeout: 30000 })

function requestStarted(): void {
  store.dispatch(setIsLoading(true))
}
function requestEnded(): void {
  requestsCount--
  clearTimeout(loadingTimer)
  if (requestsCount > 0) return
  requestsCount = 0
  loadingTimer = setTimeout(() => {
    if (requestsCount == 0) {
      store.dispatch(setIsLoading(false))
    }
  }, 500)
}

xhr.interceptors.request.use(async (config: any) => {
  const bearerToken = await StorageService.getBearerToken()
  if (bearerToken) config.headers.Authorization = `Bearer ${bearerToken}`

  requestsCount++
  requestStarted()
  return config
})

xhr.interceptors.response.use(
  response => {
    requestEnded()
    return response
  },
  async error => {
    requestEnded()
    if (error?.response?.status == 401) {
      await StorageService.setBearerToken(null)
      rootNavigate('auth')
    }
    return Promise.reject(error.response)
  }
)

export default function Api(endpoint: string) {
  return {
    async get(params: any = {}, headers: any = {}): Promise<any> {
      const res = await xhr.get(endpoint, { params, headers })
      return res.data
    },

    async put(data: any = {}) {
      const res = await xhr.put(endpoint, data)
      return res.data
    },

    async post(data: object, params: any = {}, headers: any = {}) {
      const res = await xhr.post(endpoint, data, { params: params, headers })
      return res.data
    },

    async oauth(data: object, params: any = {}) {
      const res = await xhr.post(endpoint, data, { 
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json',
        }
      })

      return res.data
    },

    async delete() {
      const res = await xhr.delete(endpoint)
      return res.data
    }
  }
}
