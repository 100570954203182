import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { useEffect, useState } from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import colors from '../../constants/colors'
import { AppStackParamList } from '../../routes/app'
import { StorageService } from '../../services/storage-service'
import Icon from '../ui/icon'
import Drawer from './drawer'

type Props = NativeStackScreenProps<AppStackParamList, any>

export default function Header(props: Props) {
  const [isDrawerOpened, setIsDrawerOpened] = useState(false)
  const [hasCart, setHasCart] = useState(false)
  const routes = props.navigation.getState()?.routes
  const currentRoute = props.route.name
  const prevRoute = routes[routes.length - 2]?.name
  const canGoBack = props.navigation.canGoBack() && prevRoute

  function goBack(): void {
    props.navigation.goBack()
  }
  function goToCard(): void {
    props.navigation.navigate('cart')
  }
  function canShowTheCartButton(): boolean {
    return hasCart && currentRoute != 'cart'
  }
  function toggleDrawer(): void {
    setIsDrawerOpened(!isDrawerOpened)
  }

  useEffect(() => {
    async function checkIfHasCart() {
      const cartId = await StorageService.getCartId()
      setHasCart(!!cartId)
    }
    checkIfHasCart()
  }, [currentRoute])

  return (
    <>
      <View style={styles.wrapper}>
        <View>
          {canGoBack && (
            <TouchableOpacity style={[styles.button, styles.secondaryButton]} onPress={goBack}>
              <Icon name="arrow-back" size={24} color="#ffffff" />
            </TouchableOpacity>
          )}
        </View>
        <View style={styles.buttonsWrapper}>
          {canShowTheCartButton() && (
            <TouchableOpacity style={[styles.button, styles.primaryButton]} onPress={goToCard}>
              <Icon name="shopping-cart" size={24} color="#ffffff" />
            </TouchableOpacity>
          )}
          {/* <TouchableOpacity style={styles.button} onPress={toggleDrawer}>
            <Icon name="menu" size={32} />
          </TouchableOpacity> */}
        </View>
      </View>
      <Drawer isOpened={isDrawerOpened} setIsOpened={setIsDrawerOpened} />
    </>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: colors.background,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 16,
    height: 64,
    width: '100%',
    maxWidth: 544,
    marginHorizontal: 'auto'
  },
  buttonsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    gap: 8
  },
  button: {
    height: 48,
    width: 48,
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  secondaryButton: { backgroundColor: colors.secondary },
  primaryButton: { backgroundColor: colors.primary }
})
