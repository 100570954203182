import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { useEffect, useState } from 'react'
import productRepository from '../../../repositories/product-repository'
import { AppStackParamList } from '../../../routes/app'
import { ProductListItem } from '../../../types/product'
import ProductCard from '../../compositions/product-card'
import Layout from '../../templates/layout'
import Col from '../../ui/col'
import Row from '../../ui/row'
import Stack from '../../ui/stack'

type Props = NativeStackScreenProps<AppStackParamList, 'product_list'>

export default function ProductListScreen(props: Props) {
  const [eventName, setEventName] = useState<string>()
  const [products, setProducts] = useState<ProductListItem[]>()

  function goToProductDetail(productId: string): void {
    props.navigation.navigate('product_detail', { productId })
  }

  useEffect(() => {
    async function fetchProducts() {
      try {
        const eventId = props.route.params.eventId
        const response = await productRepository.getAll(eventId)
        setProducts(response.products)
        setEventName(response.event)
      } catch (error) {
        console.log('error', error)
      }
    }
    fetchProducts()
  }, [])
  return (
    <Layout title={eventName}>
      <Stack>
        <Row gap={8}>
          {products?.map(product => (
            <Col key={product.id} gap={8} cols={6}>
              <ProductCard product={product} onPress={() => goToProductDetail(product.id)} />
            </Col>
          ))}
        </Row>
      </Stack>
    </Layout>
  )
}
