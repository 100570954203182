import { StyleSheet, Text, TextInput, TextInputProps, View } from 'react-native'
import colors from '../../constants/colors'

interface Props extends TextInputProps {
  label?: string
  error?: string | null
}

export default function FormTextField(props: Props) {
  const hasErrors = !!props.error
  return (
    <View>
      {props.label && <Text style={[styles.label, hasErrors ? styles.labelError : null]}>{props.label}</Text>}
      <TextInput style={[styles.input, hasErrors ? styles.inputError : null]} {...props} />
      {hasErrors && <Text style={[styles.error]}>{props.error}</Text>}
    </View>
  )
}

const styles = StyleSheet.create({
  label: {
    fontSize: 16,
    marginBottom: 4
  },
  labelError: {
    color: 'red'
  },
  error: {
    fontSize: 14,
    color: 'red',
    marginLeft: 4
  },
  input: {
    fontSize: 16,
    backgroundColor: '#eeeeee',
    minHeight: 48,
    borderRadius: 8,
    paddingHorizontal: 16,
    outlineColor: colors.primary
  },
  inputError: {
    borderWidth: 2,
    borderColor: 'red'
  }
})
