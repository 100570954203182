import { Dispatch, SetStateAction } from 'react'
import ReactNativeModal from 'react-native-modal'
import { Image, StyleSheet, Text, TouchableHighlight, View } from 'react-native'
import colors from '../../constants/colors'
import Icon from '../ui/icon'

interface Props {
  isOpened: boolean
  setIsOpened: Dispatch<SetStateAction<boolean>>
}

export default function Drawer(props: Props) {
  return (
    <ReactNativeModal
      isVisible={props.isOpened}
      animationIn="slideInRight"
      animationOut="slideOutRight"
      style={{ margin: 0 }}
      onBackButtonPress={() => props.setIsOpened(false)}
      onBackdropPress={() => props.setIsOpened(false)}
      onSwipeComplete={() => props.setIsOpened(false)}
      swipeDirection="left">
      <View style={styles.drawer}>
        <View style={styles.content}>
          <Image source={require('../../assets/white-logo.png')} />
          <View style={styles.links}>
            <TouchableHighlight style={styles.item}>
              <>
                <Icon name="local-activity" color="#ffffff" size={20} />
                <Text style={styles.itemTitle}>Reimprimir ticket</Text>
              </>
            </TouchableHighlight>
            <TouchableHighlight style={styles.item}>
              <>
                <Icon name="list-alt" color="#ffffff" size={20} />
                <Text style={styles.itemTitle}>Últimos pedidos</Text>
              </>
            </TouchableHighlight>
            <TouchableHighlight style={styles.item}>
              <>
                <Icon name="swap-horiz" color="#ffffff" size={20} />
                <Text style={styles.itemTitle}>Troca de ingresso</Text>
              </>
            </TouchableHighlight>
            <TouchableHighlight style={styles.item}>
              <>
                <Icon name="support-agent" color="#ffffff" size={20} />
                <Text style={styles.itemTitle}>Solicitar suporte</Text>
              </>
            </TouchableHighlight>
          </View>
          <TouchableHighlight style={styles.item}>
            <>
              <Icon name="logout" color="#ffffff" size={20} />
              <Text style={styles.itemTitle}>Sair</Text>
            </>
          </TouchableHighlight>
        </View>
      </View>
    </ReactNativeModal>
  )
}

const styles = StyleSheet.create({
  drawer: {
    flex: 1,
    backgroundColor: colors.secondary,
    width: '70%',
    marginLeft: '30%',
    paddingHorizontal: 32,
    paddingVertical: 96
  },
  content: {
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    gap: 16
  },
  links: {
    display: 'flex',
    justifyContent: 'center',
    gap: 32
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    gap: 8
  },
  itemTitle: {
    color: '#ffffff',
    fontSize: 16,
    fontWeight: 'bold'
  }
})
