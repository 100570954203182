import FormTextField from '../../ui/form-text-field'
import Button from '../../ui/button'
import { Image, StyleSheet, Text, View } from 'react-native'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { AuthStackParamList } from '../../../routes/auth'
import { useEffect, useState } from 'react'
import validateLogin from '../../../validation/validate-login'
import useReg from '../../../hooks/use-reg'
import AuthRepository from '../../../repositories/auth-repository'
import { StorageService } from '../../../services/storage-service'
import UserRepository from '../../../repositories/user-repository'
import { useSelector } from 'react-redux'
import { selectLoading } from '../../../stores/loading'
import Alert from '../../ui/alert'
import Stack from '../../ui/stack'
import { rootNavigate } from '../../../helpers/root-navigation'

type Props = NativeStackScreenProps<AuthStackParamList, 'login'>
interface LoginData {
  email?: string
  password?: string
}

export default function LoginScreen(props: Props) {
  const isLoading = useSelector(selectLoading)
  const [validationErrors, setValidationErros] = useState<Record<string, string>>({})
  const [loginFields, updateLoginFields] = useReg<LoginData>({})

  function validateLoginData(): boolean {
    try {
      setValidationErros({})
      validateLogin({
        email: loginFields.email,
        password: loginFields.password
      })
      return true
    } catch (error: any) {
      setValidationErros(JSON.parse(error.message))
      return false
    }
  }
  async function formSend(): Promise<void> {
    if (!validateLoginData()) return
    try {
      const loginData = loginFields as Required<LoginData>
      const formData = new FormData()
      formData.append('grant_type', 'password')
      formData.append('client_id', '9985fd85-8271-4654-a901-938afcb6fd3f')
      formData.append('client_secret', 'yR00NlcAhBmDGmq5kk7Vwp3B56VvlCfL5XmL9xDR')
      formData.append('username', loginData.email)
      //formData.append('password', 'password')
      formData.append('password', loginData.password)
      console.log('loginData', loginData)
      const authResponse = await AuthRepository.authenticate({
        grant_type: 'password',
        client_id: '9985fd85-8271-4654-a901-938afcb6fd3f',
        client_secret: 'yR00NlcAhBmDGmq5kk7Vwp3B56VvlCfL5XmL9xDR',
        username: loginData.email,
        password: loginData.password
      })
      await StorageService.setBearerToken(authResponse.access_token)
      console.log('user access_token', authResponse.access_token)
      const user = await UserRepository.get()
      console.log('user', user)
      if (user && user.user_type !== 'pos') {
        throw new Error('Usuário inválido')
      }
      goToApp()
    } catch (error: any) {
      console.log('error', error)
      await StorageService.setBearerToken(null)
      setValidationErros({ data: error.data ? error.data.message : 'Usuário inválido' })
    }
  }
  function goToApp(): void {
    rootNavigate('app')
  }

  useEffect(() => {
    async function checkAuth() {
      const bearerToken = await StorageService.getBearerToken()
      if (!bearerToken) return
      goToApp()
    }
    checkAuth()
  }, [])

  return (
    <View style={styles.screen}>
      <Image style={styles.image} source={require('../../../assets/logo.png')} />
      <View style={styles.form}>
        <FormTextField
          label="E-mail"
          placeholder="E-mail"
          error={validationErrors.email}
          value={loginFields.email}
          onChangeText={email => updateLoginFields(email, 'email')}
        />
        <FormTextField
          label="Senha"
          placeholder="Senha"
          error={validationErrors.password}
          value={loginFields.password}
          secureTextEntry={true}
          onChangeText={password => updateLoginFields(password, 'password')}
        />
      </View>
      <Stack gap={8}>
        {validationErrors.data && <Alert label={validationErrors.data} />}
        <Button label="Acessar" onPress={formSend} loading={isLoading} />
      </Stack>
    </View>
  )
}

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    paddingHorizontal: 16,
    display: 'flex',
    justifyContent: 'center',
    gap: 64,
    width: '100%',
    maxWidth: 544,
    marginHorizontal: 'auto'
  },
  image: {
    width: 200,
    height: 94,
    alignSelf: 'center'
  },
  form: {
    display: 'flex',
    gap: 8
  }
})
