import { useState } from 'react'
import { StyleSheet, Text, TouchableHighlight } from 'react-native'
import Icon from '../ui/icon'
import Stack from '../ui/stack'
import Modal from './modal'
import colors from '../../constants/colors'
import { Place, Sector } from '../../types/product-options'

interface Props {
  label: string
  options: Sector[] | Place[]
  setCurrent: (value?: string) => void
  current?: string
  error?: string | null
}

export default function ModalSelector(props: Props) {
  const [isSectionModalOpened, setIsSectionModalOpened] = useState(false)
  const hasErrors = !!props.error

  function openSelectionModal(): void {
    setIsSectionModalOpened(true)
  }
  function hasSomethingSelected(): boolean {
    return !!props.current
  }
  function getButtonLabel() {
    if (!hasSomethingSelected()) return props.label
    return props.options.find(option => option.id == props.current)?.name
  }
  function isSelected(optionValue: string): boolean {
    return optionValue == props.current
  }
  function selectOption(optionValue?: string): void {
    props.setCurrent(optionValue)
    setIsSectionModalOpened(false)
  }

  return (
    <>
      <TouchableHighlight
        activeOpacity={0.9}
        underlayColor="#dddddd"
        style={[styles.button, hasSomethingSelected() ? styles.activeButton : null, hasErrors ? styles.error : null]}
        onPress={openSelectionModal}>
        <>
          <Text style={[styles.buttonText, hasSomethingSelected() ? styles.activeButtonText : null]}>
            {getButtonLabel()}
          </Text>
          <Icon name="arrow-forward" size={20} color={hasSomethingSelected() ? '#ffffff' : '#000000'} />
        </>
      </TouchableHighlight>
      <Modal title={props.label} isOpened={isSectionModalOpened} setIsOpened={setIsSectionModalOpened} fullHeight>
        <Stack gap={8}>
          {props.options.map(option => (
            <TouchableHighlight
              key={option.id}
              activeOpacity={0.9}
              underlayColor="#dddddd"
              style={[styles.button, isSelected(option.id) ? styles.activeButton : null]}
              onPress={() => selectOption(option.id)}>
              <Text style={[styles.buttonText, isSelected(option.id) ? styles.activeButtonText : null]}>
                {option.name}
              </Text>
            </TouchableHighlight>
          ))}
        </Stack>
      </Modal>
    </>
  )
}

const styles = StyleSheet.create({
  button: {
    backgroundColor: '#eeeeee',
    borderRadius: 8,
    minHeight: 64,
    paddingHorizontal: 32,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  activeButton: {
    backgroundColor: colors.primary
  },
  buttonText: {
    fontSize: 16
  },
  error: {
    borderWidth: 2,
    borderColor: 'red'
  },
  activeButtonText: {
    color: '#ffffff',
    fontWeight: 'bold'
  }
})
