import { useEffect, useState } from 'react'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { AppStackParamList } from '../../../routes/app'
import productRepository from '../../../repositories/product-repository'
import productService from '../../../services/product-service'
import { ProductDetails } from '../../../types/product'
import ProductDetailSection from '../../compositions/product-detail-section'
import Layout from '../../templates/layout'
import Stack from '../../ui/stack'
import Tabs from '../../compositions/tabs'
import { FormSelectOption } from '../../../routes/form-select-option'
import DateSelection from '../../compositions/date-selection'
import ModalSelector from '../../compositions/modal-selector'
import useReg from '../../../hooks/use-reg'
import TicketAmountSelection from '../../compositions/ticket-amount-selection'
import FormTextField from '../../ui/form-text-field'
import Total from '../../compositions/total'
import Button from '../../ui/button'
import { Parking, Place, Sector, Ticket, CombinationOption } from '../../../types/product-options'
import Modal from '../../compositions/modal'
import cartRepository from '../../../repositories/cart-repository'
import { CartTicket } from '../../../types/cart-requests'
import { StorageService } from '../../../services/storage-service'
import { Text } from 'react-native'

type Props = NativeStackScreenProps<AppStackParamList, 'product_detail'>

type CombinationOptionWithAmount = CombinationOption & {
  amount: number
}

export default function ProductDetailScreen(props: Props) {
  const [isAddedModalOpened, setIsAddedModalOpened] = useState(false)
  const [productDetails, setProductDetails] = useState<ProductDetails>()
  const [currentTab, setCurrentTab] = useState('ticket_and_parking')
  const [sectorList, setSectorList] = useState<Sector[]>([])
  const [placeList, setPlaceList] = useState<Place[]>([])
  const [ticket, setTicket] = useState<Ticket>()
  const [parkingList, setParkingList] = useState<Parking[]>([])
  const [productConfig, updateProductConfig] = useReg<{
    tickets: Record<string, CombinationOptionWithAmount>
    parkings: Record<string, CombinationOptionWithAmount>
    dateId?: string
    sectorId?: string
    placeId?: string
  }>({
    tickets: {},
    parkings: {}
  })
  const tabs: FormSelectOption[] = [
    { label: 'Ticket + Estacionamento', value: 'ticket_and_parking' },
    { label: 'Apenas estacionamento', value: 'just_parking' }
  ]

  async function changeSelectedDateId(dateId?: string): Promise<void> {
    updateProductConfig(dateId, 'dateId')
    updateProductConfig(undefined, 'sectorId')
    updateProductConfig(undefined, 'placeId')
    updateProductConfig({}, 'tickets')
    updateProductConfig({}, 'parkings')
    await loadSectors(dateId)
    await loadParkings(dateId)
    setPlaceList([])
    setTicket(undefined)
  }
  async function loadSectors(dateId?: string): Promise<void> {
    if (!productDetails || !dateId) return
    const sectors = await productService.getSectorList(productDetails.id, dateId)
    setSectorList(sectors)
  }
  async function loadParkings(dateId?: string): Promise<void> {
    if (!productDetails || !dateId) return
    const parkings = await productService.getParkings(productDetails.id, dateId)
    setParkingList(parkings)
  }
  async function loadPlaces(dateId?: string, sectorId?: string): Promise<void> {
    if (!productDetails || !dateId || !sectorId) return
    const places = await productService.getPlaceList(productDetails.id, dateId, sectorId)
    setPlaceList(places)
  }
  async function changeSelectedSectorId(sectorId?: string): Promise<void> {
    updateProductConfig(sectorId, 'sectorId')
    updateProductConfig(undefined, 'placeId')
    await loadPlaces(productConfig.dateId, sectorId)
    await loadTicket()
  }
  async function changeSelectedPlaceId(placeId?: string): Promise<void> {
    updateProductConfig(placeId, 'placeId')
    updateProductConfig({}, 'tickets')
    await loadTicket()
  }
  async function loadTicket(): Promise<void> {
    if (!productConfig.sectorId || !productConfig.placeId || !productConfig.dateId || !productDetails) return
    const loadedTicket = await productService.getTicket(
      productDetails.id,
      productConfig.dateId,
      productConfig.sectorId,
      productConfig.placeId
    )
    setTicket(loadedTicket)
  }
  function getTicketList(): CombinationOption[] {
    if (!ticket) return []
    const tickets = [{ id: ticket.id, name: 'Inteira', type: 'full', price: ticket.total, stock: ticket.stock_count }]
    if (!['4e59da69-5c0e-47c1-8392-ed509e7aec8a', '06f56730-4d7f-470d-8c65-133cfc173c91'].includes(productConfig.dateId!)) {
      tickets.push({ id: ticket.id, name: 'Meia', type: 'half', price: ticket.half, stock: ticket.stock_count })
    }
    return tickets
  }
  function getParkingList(): CombinationOption[] {
    if (!parkingList) return []
    const list: CombinationOption[] = []
    parkingList.forEach(parking => {
      list.push({ id: parking.id, name: parking.options[0].name, type: parking.id, price: parking.total })
    })
    return list
  }
  function updateProductConfigTickets(ticket: CombinationOption, amount: number): void {
    updateProductConfig({ ...productConfig.tickets, [`${ticket.type}${ticket.id}`]: { ...ticket, amount } }, 'tickets')
  }
  function updateProductConfigParkings(parking: CombinationOption, amount: number): void {
    updateProductConfig({ ...productConfig.parkings, [parking.id]: { ...parking, amount } }, 'parkings')
  }
  function hasSomeParkingSelected(): boolean {
    getParkingList().forEach(parking => {
      if (productConfig.parkings[parking.id] && productConfig.parkings[parking.id].amount > 0) return true
    })
    return false
  }
  function getTicketSum(): number {
    let total = 0
    getTicketList().forEach(ticket => {
      total +=
        ticket.price *
        (productConfig.tickets[`${ticket.type}${ticket.id}`]
          ? productConfig.tickets[`${ticket.type}${ticket.id}`].amount
          : 0)
    })
    return total
  }
  function getParkingSum(): number {
    let total = 0
    getParkingList().forEach(parking => {
      total += parking.price * (productConfig.parkings[parking.id] ? productConfig.parkings[parking.id].amount : 0)
    })
    return total
  }
  function getItemsSum(): number {
    return getTicketSum() + getParkingSum()
  }
  async function formSend(): Promise<void> {
    await addItemsToCart()
  }
  async function addItemsToCart(): Promise<void> {
    if (!productDetails) return
    const ticketsToAdd: CartTicket[] = []
    if (Object.keys(productConfig.tickets).length)
      Object.keys(productConfig.tickets).forEach(key => {
        const ticket = productConfig.tickets[key]
        ticketsToAdd.push({
          combination_id: ticket.id,
          date_id: productConfig.dateId!,
          product_id: productDetails.id,
          quantity: ticket.amount,
          ticket_type: ticket.type == 'half' ? 'half' : 'normal'
        })
      })
    if (Object.keys(productConfig.parkings).length)
      Object.keys(productConfig.parkings).forEach(key => {
        const parking = productConfig.parkings[key]
        ticketsToAdd.push({
          combination_id: parking.id,
          date_id: productConfig.dateId!,
          product_id: productDetails.id,
          quantity: parking.amount,
          ticket_type: 'normal'
        })
      })
    const savedCartId = await StorageService.getCartId()
    const cartId = await cartRepository.add(ticketsToAdd, savedCartId)
    await StorageService.setCartId(cartId)
    setIsAddedModalOpened(true)
  }
  function canShowAddToCartButton(): boolean {
    const hasTicketsSelected = !!getTicketSum() && currentTab == 'ticket_and_parking'
    const hasParkingsSelected = !!getParkingSum() && currentTab == 'just_parking'
    return hasTicketsSelected || hasParkingsSelected
  }
  function goToEvents(): void {
    setIsAddedModalOpened(false)
    props.navigation.navigate('events')
  }
  function goToCart(): void {
    setIsAddedModalOpened(false)
    props.navigation.navigate('cart')
  }

  useEffect(() => {
    const productId = props.route.params.productId
    async function fetchProductDetails() {
      try {
        const productDetails = await productRepository.getDetails(productId)
        setProductDetails(productDetails)
      } catch (error) {
        console.log('error', error)
      }
    }
    fetchProductDetails()
  }, [])
  return (
    <>
      <Layout title={productDetails?.name} bannerImage={productDetails?.image}>
        <Stack gap={16}>
          <ProductDetailSection title="Data">
            <DateSelection
              dates={productDetails?.dates ?? []}
              selectedDateId={productConfig.dateId}
              setSelectedDateId={changeSelectedDateId}
            />
          </ProductDetailSection>
          {productConfig.dateId && getParkingList().length ? (
            <ProductDetailSection title="Tipo de compra">
              <Tabs options={tabs} currentTab={currentTab} setSelectedTab={setCurrentTab} />
            </ProductDetailSection>
          ) : null}
          {currentTab == 'ticket_and_parking' && (
            <>
              {sectorList.length > 0 && (
                <ProductDetailSection title="Setor">
                  <ModalSelector
                    label="Selecione um setor"
                    options={sectorList}
                    current={productConfig.sectorId}
                    setCurrent={changeSelectedSectorId}
                  />
                </ProductDetailSection>
              )}
              {placeList.length > 0 && (
                <ProductDetailSection title="Local">
                  <ModalSelector
                    label="Selecione um local"
                    options={placeList}
                    current={productConfig.placeId}
                    setCurrent={changeSelectedPlaceId}
                  />
                </ProductDetailSection>
              )}
              {ticket && (
                <ProductDetailSection title="Ingressos">
                  <Stack gap={8}>
                    {getTicketList().map(ticket => (
                      <TicketAmountSelection
                        key={ticket.type}
                        name={ticket.name}
                        price={ticket.price}
                        stock={ticket.stock}
                        amount={
                          productConfig.tickets && productConfig.tickets[`${ticket.type}${ticket.id}`]
                            ? productConfig.tickets[`${ticket.type}${ticket.id}`].amount
                            : 0
                        }
                        setAmount={amount => updateProductConfigTickets(ticket, amount)}
                      />
                    ))}
                  </Stack>
                </ProductDetailSection>
              )}
            </>
          )}
          {parkingList && (ticket || currentTab == 'just_parking') && (
            // <ProductDetailSection title="Estacionamento">
            <ProductDetailSection title="">
              <Stack gap={8}>
                {getParkingList().map(parking => (
                  <TicketAmountSelection
                    key={parking.id}
                    name={parking.name}
                    price={parking.price}
                    amount={
                      productConfig.parkings && productConfig.parkings[parking.id]
                        ? productConfig.parkings[parking.id].amount
                        : 0
                    }
                    setAmount={amount => updateProductConfigParkings(parking, amount)}
                  />
                ))}
              </Stack>
            </ProductDetailSection>
          )}
          {(ticket || (parkingList && currentTab == 'just_parking')) && (
            <>
              {hasSomeParkingSelected() ? (
                <FormTextField label="Placa do veiculo" placeholder="Placa do veiculo" />
              ) : null}
              <Total value={getItemsSum()} />
              <Button label="Adicionar ao carrinho" size="lg" disabled={!canShowAddToCartButton()} onPress={formSend} />
            </>
          )}
        </Stack>
      </Layout>
      <Modal title="Produtos adicionados ao carrinho" isOpened={isAddedModalOpened} setIsOpened={setIsAddedModalOpened}>
        <Stack gap={8}>
          <Button label="Continuar comprando" size="lg" variant="muted" onPress={goToEvents} />
          <Button label="Ir para o carrinho" size="lg" onPress={goToCart} />
        </Stack>
      </Modal>
    </>
  )
}
