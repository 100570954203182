import { Image, StyleSheet, Text, TouchableHighlight, TouchableHighlightProps } from 'react-native'
import colors from '../../constants/colors'
import { ProductListItem } from '../../types/product'
import Button from '../ui/button'

interface Props extends TouchableHighlightProps {
  product: ProductListItem
}

export default function ProductCard(props: Props) {
  return (
    <TouchableHighlight activeOpacity={0.9} underlayColor={colors.background} {...props}>
      <>
        <Image style={styles.image} source={{ uri: props.product.image }} />
        <Text style={styles.title}>{props.product.name}</Text>
        <Button label="Comprar ingresso" onPress={props.onPress} />
      </>
    </TouchableHighlight>
  )
}

const styles = StyleSheet.create({
  image: {
    width: '100%',
    aspectRatio: '1 / 1',
    borderRadius: 8
  },
  title: {
    fontWeight: 'bold',
    marginVertical: 8,
    lineHeight: 18,
    height: 36
  }
})
