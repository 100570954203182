import { StyleSheet, Text, View } from 'react-native'
import Icon from './icon'

interface Props {
  label: string
}

export default function Alert(props: Props) {
  return (
    <View style={styles.alert}>
      <Icon name="error-outline" color="#ffffff" size={24} />
      <Text style={styles.text}>{props.label}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  alert: {
    backgroundColor: 'red',
    paddingVertical: 8,
    paddingHorizontal: 16,
    fontWeight: 'bold',
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8
  },
  text: {
    color: 'white',
    fontSize: 16
  }
})
