import Api from '../services/api-service'
import { AuthenticatePayload, AuthenticateResponse } from '../types/auth-requests'

const AuthRepository = {
  async authenticate(payload: AuthenticatePayload): Promise<AuthenticateResponse> {
    const headers = { 'Content-Type': 'application/x-www-form-urlencoded' }
    return await Api(`/oauth/token`).post(payload, {}, headers)
    // return await Promise.resolve({ access_token: 'accesstokenloremipsumdolorsitamet' })
  }
}

export default AuthRepository
