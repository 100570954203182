import { ScrollView, StyleSheet, Text, TouchableHighlight, View, Dimensions } from 'react-native'
import colors from '../../constants/colors'
import zeroFill from '../../filters/zero-fill'
import { Date } from '../../types/date'

interface Props {
  setSelectedDateId: (dateId?: string) => void
  dates: Date[]
  selectedDateId?: string
}

export default function DateSelection(props: Props) {
  function sanitizeMonthName(monthName: string): string {
    return monthName.substring(0, 3)
  }
  return (
    <ScrollView contentContainerStyle={styles.scroll} horizontal showsHorizontalScrollIndicator={false}>
      {props.dates.map(date => (
        <TouchableHighlight
          activeOpacity={0.9}
          underlayColor="#dddddd"
          key={date.id}
          style={[styles.date, props.selectedDateId === date.id ? styles.activeDate : null]}
          onPress={() => props.setSelectedDateId(date.id)}>
          <View>
            <Text style={[styles.text, props.selectedDateId === date.id ? styles.activeText : null, styles.day]}>
              {zeroFill(date.day)}
            </Text>
            <Text style={[styles.text, props.selectedDateId === date.id ? styles.activeText : null]}>
              {sanitizeMonthName(date.month)} {date.year}
            </Text>
          </View>
        </TouchableHighlight>
      ))}
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  scroll: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    gap: 8
  },
  date: {
    backgroundColor: '#eeeeee',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 76,
    aspectRatio: '1 / 1',
    borderRadius: 8
  },
  activeDate: {
    backgroundColor: colors.primary
  },
  text: {
    fontSize: 12,
    color: '#000000',
    textAlign: 'center',
    fontWeight: 'bold'
  },
  activeText: {
    color: '#ffffff'
  },
  day: {
    fontSize: 24
  }
})
