import validateEmail from './validate-email'
import validateRequired from './validate-required'

interface LoginData {
  email?: string | null
  password?: string | null
}

export default function validateLogin(data: LoginData): boolean {
  const errors: Record<string, string> = {}
  if (!validateEmail(data.email)) errors.email = 'E-mail inválido'
  if (!validateRequired(data.password)) errors.password = 'Senha inválida'
  if (Object.keys(errors).length > 0) throw new Error(JSON.stringify(errors))
  return true
}
