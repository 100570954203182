import Layout from '../../templates/layout'
import { Cart, PaymentItem } from '../../../types/cart'
import { useEffect, useState } from 'react'
import { StorageService } from '../../../services/storage-service'
import cartRepository from '../../../repositories/cart-repository'
import { StyleSheet, Text } from 'react-native'
import Radio from '../../ui/radio'
import Stack from '../../ui/stack'
import Total from '../../compositions/total'
import Button from '../../ui/button'
import colors from '../../../constants/colors'
import orderRepository from '../../../repositories/order-repository'

export default function PaymentScreen() {
  const [cartData, setCartData] = useState<Cart>()
  const [orderId, setOrderId] = useState<string>()
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string>()
  const [selectedParcel, setSelectedParcel] = useState<number>(1)
  const [printData, setPrintData] = useState<string>()

  function getTotal(): number {
    const paymentMethod = cartData?.payments.find(paymentMethod => paymentMethod.id == selectedPaymentMethod)
    const parcel = paymentMethod?.items.find(item => item.id == selectedParcel)
    if (!paymentMethod || !parcel) return 0
    return parcel.total
  }
  function getParcels(): PaymentItem[] {
    const paymentMethod = cartData?.payments.find(paymentMethod => paymentMethod.id == selectedPaymentMethod)
    if (!paymentMethod) return []
    return paymentMethod.items
  }
  function changePaymentMethod(value: string): void {
    setSelectedPaymentMethod(value)
    setSelectedParcel(1)
  }
  async function onFinalize(): Promise<void> {
    const approvedOrder = await orderRepository.approve({
      payment_method: selectedPaymentMethod!,
      order_id: orderId!,
      installments: selectedParcel
    })
    setPrintData(approvedOrder.print_html)
  }
  function onPrint(): void {
    const printWindow = window.open('', 'PRINT', 'height=400,width=600')
    if (!printWindow || !printData) return
    printWindow.document.write('<html><body>')
    printWindow.document.write(printData)
    printWindow.document.write('</body></html>')
    printWindow.focus()
  }

  useEffect(() => {
    async function loadCartItems() {
      const cartId = await StorageService.getCartId()
      if (!cartId) return
      const cart = await cartRepository.get(cartId)
      const responseOrderId = await orderRepository.create(cartId)
      await StorageService.setCartId(null)
      setOrderId(responseOrderId)
      setCartData(cart)
    }
    loadCartItems()
  }, [])
  return (
    <Layout title="Finalizar compra">
      {!printData ? (
        <>
          <Stack gap={32}>
            <Stack gap={8}>
              {cartData?.payments.map(paymentMethod => (
                <Radio
                  key={paymentMethod.id}
                  value={paymentMethod.id}
                  selected={selectedPaymentMethod}
                  setSelected={changePaymentMethod}
                  label={paymentMethod.name}
                />
              ))}
            </Stack>
            {getParcels().length > 1 ? (
              <Stack gap={8}>
                <Text style={styles.title}>Parcelas</Text>
                {getParcels().map(parcel => (
                  <Radio
                    key={parcel.id}
                    value={parcel.id}
                    selected={selectedParcel}
                    setSelected={setSelectedParcel}
                    label={parcel.name}
                  />
                ))}
              </Stack>
            ) : null}
            <Stack gap={8}>
              <Total value={getTotal()} />
            </Stack>
          </Stack>
          <Button label="Aprovar compra" size="lg" disabled={!selectedPaymentMethod} onPress={() => onFinalize()} />
        </>
      ) : (
        <Button label="Imprimir" size="lg" onPress={() => onPrint()} />
      )}
    </Layout>
  )
}

const styles = StyleSheet.create({
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 8,
    color: colors.primary
  }
})
