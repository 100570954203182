import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { User } from '../types/user'

interface UserState {
  data: User | null
}

export const slice = createSlice({
  name: 'user',
  initialState: <UserState>{
    data: null
  },
  reducers: {
    setUser(state: UserState, action: PayloadAction<User | null>): UserState {
      return { ...state, data: action.payload }
    }
  }
})

export const { setUser } = slice.actions
export default slice.reducer
