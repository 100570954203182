import { productDetailsMock, productOptionsMock, productsListMock } from '../mocks/products-mock'
import { ProductGetAllReturn, ProductGetDetailsReturn, ProductGetOptionsReturn } from '../types/product-requests'
import { ProductOption } from '../types/product-options'
import Api from '../services/api-service'

let productOptions: ProductOption[] | undefined

const productRepository = {
  async getAll(eventId: string): Promise<ProductGetAllReturn> {
    const response = await Api(`/api/pos/products/${eventId}`).get()
    return { products: response.data, event: response.message }
    // return await Promise.resolve({ products: productsListMock, event: '38º Natal Luz de Gramado' })
  },
  async getDetails(productId: string): Promise<ProductGetDetailsReturn> {
    productOptions = undefined
    const response = await Api(`/api/pos/product/${productId}`).get()
    return response.data
    // return await Promise.resolve(productDetailsMock)
  },
  async getOptions(productId: string, dateId: string): Promise<ProductGetOptionsReturn> {
    // if (!productOptions) {
      const response = await Api(`/api/products/options/${productId}/${dateId}`).get()
      return response.data
    // }
    // if (!productOptions) productOptions = await Promise.resolve(productOptionsMock)
    return productOptions!
  }
}

export default productRepository
