import { StyleSheet, Text, TouchableHighlight, View } from 'react-native'
import colors from '../../constants/colors'
import { FormSelectOption } from '../../routes/form-select-option'

interface Props {
  currentTab: string
  setSelectedTab: (tab: string) => void
  options: FormSelectOption[]
}

export default function Tabs(props: Props) {
  return (
    <View style={styles.wrapper}>
      {props.options.map((option, index) => (
        <TouchableHighlight
          activeOpacity={0.9}
          underlayColor="#dddddd"
          style={[
            styles.tab,
            props.currentTab === option.value ? styles.activeTab : null,
            index === 0 ? styles.firstTab : null,
            index === props.options.length - 1 ? styles.lastTab : null
          ]}
          key={option.value}
          onPress={() => props.setSelectedTab(option.value)}>
          <Text style={[styles.label, props.currentTab === option.value ? styles.activeLabel : null]}>
            {option.label}
          </Text>
        </TouchableHighlight>
      ))}
    </View>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center'
  },
  tab: {
    flex: 1,
    backgroundColor: '#eeeeee',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 64,
    paddingHorizontal: 16
  },
  activeTab: {
    backgroundColor: colors.primary
  },
  firstTab: {
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8
  },
  lastTab: {
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8
  },
  label: {
    fontSize: 16,
    color: '#666666',
    textAlign: 'center',
    fontWeight: 'bold'
  },
  activeLabel: {
    color: '#ffffff'
  }
})
