import Api from '../services/api-service'
import { cartMock } from '../mocks/cart-mocks'
import { CartAddRequest, CartAddResponse, CartGetResponse } from '../types/cart-requests'

const cartRepository = {
  async get(cartId: string): Promise<CartGetResponse> {
    const response = await Api(`/api/pos/cart/${cartId}`).get()
    return response.data
    // return await Promise.resolve(cartMock)
  },
  async add(tickets: CartAddRequest, cartId?: string | null): Promise<CartAddResponse> {
    const response = await Api(`/api/pos/cart${cartId ? `/${cartId}` : ''}`).post({ tickets })
    return response.data.cart_id
    // return await Promise.resolve('cart1')
  },
  async remove(cartId: string, itemId: string): Promise<void> {
    await Api(`/api/pos/cart/${cartId}/${itemId}`).delete()
    // return await Promise.resolve()
  }
}

export default cartRepository
