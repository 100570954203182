import { StyleSheet, View, ViewProps, ViewStyle } from 'react-native'

interface Props extends ViewProps {
  gap?: number
  verticalAlign?: 'center' | 'top' | 'bottom'
}

export default function Row(props: Props) {
  const gapValue = Number(props.gap) * -0.5
  const gapStyle = props.gap ? { marginHorizontal: gapValue, marginVertical: gapValue } : null
  function getVerticalAlignStyle(): ViewStyle | null {
    switch (props.verticalAlign) {
      case 'bottom':
        return styles.alignBottom
      case 'top':
        return styles.alignTop
      case 'center':
        return styles.alignCenter
      default:
        return null
    }
  }
  return (
    <View style={[styles.row, getVerticalAlignStyle(), gapStyle]} {...props}>
      {props.children}
    </View>
  )
}

const styles = StyleSheet.create({
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  alignBottom: { alignItems: 'flex-start' },
  alignTop: { alignItems: 'flex-end' },
  alignCenter: { alignItems: 'center' }
})
