import { StyleSheet, Text, TouchableHighlight, View } from 'react-native'
import colors from '../../constants/colors'
import Icon from './icon'

interface Props {
  amount: number
  setAmount(amount: number): void
}

export default function AmountSelector(props: Props) {
  function add(): void {
    props.setAmount(props.amount + 1)
  }
  function remove(): void {
    if (props.amount <= 0) return
    props.setAmount(props.amount - 1)
  }
  return (
    <View style={styles.wrapper}>
      <TouchableHighlight style={styles.button} onPress={remove}>
        <Icon name="remove" color="#ffffff" size={20} />
      </TouchableHighlight>
      <Text style={styles.text}>{props.amount}</Text>
      <TouchableHighlight style={styles.button} onPress={add}>
        <Icon name="add" color="#ffffff" size={20} />
      </TouchableHighlight>
    </View>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 8
  },
  button: {
    backgroundColor: colors.secondary,
    width: 42,
    height: 42,
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  text: {
    width: 32,
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center'
  }
})
