import { createNativeStackNavigator, NativeStackScreenProps } from '@react-navigation/native-stack'
import EventsScreen from '../components/screens/app/events-screen'
import ProductListScreen from '../components/screens/app/product-list-screen'
import ProductDetailScreen from '../components/screens/app/product-detail-screen'
import Header from '../components/compositions/header'
import CartScreen from '../components/screens/app/cart-screen'
import { RootStackParamList } from '.'
import PaymentScreen from '../components/screens/app/payment-screen'

export type AppStackParamList = {
  events: undefined
  product_list: { eventId: string }
  product_detail: { productId: string }
  cart: undefined
  payment: undefined
}

type Props = NativeStackScreenProps<RootStackParamList, 'app'>

const AppStack = createNativeStackNavigator<AppStackParamList>()
export default function AppRoutes(props: Props) {
  return (
    <AppStack.Navigator initialRouteName="events" screenOptions={props => ({ header: () => <Header {...props} /> })}>
      <AppStack.Screen name="events" component={EventsScreen} />
      <AppStack.Screen name="product_list" component={ProductListScreen} />
      <AppStack.Screen name="product_detail" component={ProductDetailScreen} />
      <AppStack.Screen name="cart" component={CartScreen} />
      <AppStack.Screen name="payment" component={PaymentScreen} />
    </AppStack.Navigator>
  )
}
