import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '.'

interface LoadingState {
  data: boolean
}

export const slice = createSlice({
  name: 'loading',
  initialState: <LoadingState>{
    data: false
  },
  reducers: {
    setIsLoading(state: LoadingState, action: PayloadAction<boolean>): LoadingState {
      return { ...state, data: action.payload }
    }
  }
})

export const selectLoading = createSelector(
  (state: RootState) => state.loading,
  counter => counter.data
)

export const { setIsLoading } = slice.actions
export default slice.reducer
