import { ActivityIndicator, StyleSheet, Text, TouchableOpacity, TouchableOpacityProps } from 'react-native'
import colors from '../../constants/colors'

interface Props extends TouchableOpacityProps {
  label?: string
  size?: 'sm' | 'md' | 'lg'
  disabled?: boolean
  variant?: 'primary' | 'muted'
  loading?: boolean
}

export default function Button(props: Props) {
  const sizeStyle = props.size ? styles[props.size] : styles.md
  const disabledStyle = props.disabled ? styles.disabled : null
  const variantStyle = props.variant ? styles[props.variant] : styles.primary
  const variantTextStyle = props.variant == 'muted' ? styles.darkText : styles.lightText
  return (
    <TouchableOpacity
      disabled={props.disabled}
      style={[styles.button, sizeStyle, disabledStyle, variantStyle]}
      {...props}>
      {props.loading ? (
        <ActivityIndicator size="small" color="#ffffff" />
      ) : (
        <Text style={[styles.text, variantTextStyle]}>{props.label}</Text>
      )}
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  button: {
    borderRadius: 8,
    paddingHorizontal: 12,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8
  },
  text: {
    fontSize: 16,
    fontWeight: 'bold'
  },
  lightText: {
    color: '#ffffff'
  },
  darkText: {
    color: '#000000'
  },
  sm: {
    minHeight: 32
  },
  md: {
    minHeight: 48
  },
  lg: {
    minHeight: 64
  },
  disabled: {
    opacity: 0.5
  },
  primary: {
    backgroundColor: colors.primary
  },
  muted: {
    backgroundColor: '#eeeeee'
  }
})
