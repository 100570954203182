import { configureStore } from '@reduxjs/toolkit'
import userReducer from './user'
import loadingReducer from './loading'
import cartReducer from './cart'

const store = configureStore({
  reducer: {
    user: userReducer,
    loading: loadingReducer,
    cart: cartReducer
  }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export default store
