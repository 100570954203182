import Api from '../services/api-service'
import { OrderApproveRequest, OrderApproveResponse, OrderCreateResponse } from '../types/order-requests'

const orderRepository = {
  async create(cartId: string): Promise<OrderCreateResponse> {
    const response = await Api(`/api/pos/order`).post({ cart_id: cartId })
    return response.data.id
  },
  async approve(data: OrderApproveRequest): Promise<OrderApproveResponse> {
    const response = await Api(`/api/pos/order/approve`).post(data)
    return response.data[0]
  }
}

export default orderRepository
