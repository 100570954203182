import { StyleSheet, Text, View } from 'react-native'
import currency from '../../filters/currency'

interface Props {
  value: number
}

export default function Total(props: Props) {
  return (
    <View style={styles.wrapper}>
      <Text style={styles.label}>Valor total</Text>
      <Text style={styles.price}>{currency(props.value)}</Text>
    </View>
  )
}
const styles = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    justifyContent: 'center',
    gap: 8
  },
  label: {
    fontSize: 14,
    color: '#666666',
    fontWeight: 'bold'
  },
  price: {
    fontSize: 24,
    fontWeight: 'bold'
  }
})
