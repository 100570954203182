import {
  ProductGetParkingsReturn,
  ProductGetPlaceReturn,
  ProductGetSectorReturn,
  ProductGetTicketReturn
} from '../types/product-requests'
import productRepository from '../repositories/product-repository'

const productService = {
  async getSectorList(productId: string, dateId: string): Promise<ProductGetSectorReturn> {
    const options = await productRepository.getOptions(productId, dateId)
    const tickets = options.find(option => option.type == 'ticket')
    const sectors = tickets?.options.find(ticketOption => ticketOption.name == 'SETOR')
    return sectors?.options ?? []
  },
  async getPlaceList(productId: string, dateId: string, sectorId: string): Promise<ProductGetPlaceReturn> {
    const options = await productRepository.getOptions(productId, dateId)
    const tickets = options.find(option => option.type == 'ticket')
    const combinations = tickets?.combinations.filter(combination => {
      return [combination.options[0].id, combination.options[1].id].includes(sectorId)
    })
    const places = combinations?.map(combination => combination.options[1])
    return places ?? []
  },
  async getTicket(
    productId: string,
    dateId: string,
    sectorId: string,
    placeId: string
  ): Promise<ProductGetTicketReturn> {
    const options = await productRepository.getOptions(productId, dateId)
    const tickets = options.find(option => option.type == 'ticket')
    const combination = tickets?.combinations.find(combination => {
      const matchers = [sectorId, placeId]
      const firstMatch = matchers.includes(combination.options[0].id)
      const secondMatch = matchers.includes(combination.options[1].id)
      return firstMatch && secondMatch
    })!
    return combination
  },
  async getParkings(productId: string, dateId: string): Promise<ProductGetParkingsReturn> {
    const options = await productRepository.getOptions(productId, dateId)
    const parking = options.find(option => option.type == 'parking')
    return parking?.combinations ?? []
  }
}

export default productService
