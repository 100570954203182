import { StyleSheet, Text, View } from 'react-native'
import currency from '../../filters/currency'
import AmountSelector from '../ui/amount-selector'

interface Props {
  name: string
  price: number
  amount: number
  stock?: number
  setAmount(amount: number): void
}

export default function TicketAmountSelection(props: Props) {
  return (
    <View style={styles.wrapper}>
      <View style={styles.header}>
        <Text style={styles.title}>{props.name}</Text>
        <Text style={styles.price}>{currency(props.price)}</Text>
        <Text style={styles.price}>Estoque: {props.stock}</Text>
      </View>
      {Number(props.stock) > 0 ? <AmountSelector amount={props.amount} setAmount={props.setAmount} /> : null}
    </View>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 8
  },
  header: {
    flex: 1
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold'
  },
  price: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#666666'
  }
})
