import { DefaultTheme, NavigationContainer } from '@react-navigation/native'
import { StatusBar } from 'expo-status-bar'
import { SafeAreaProvider, SafeAreaView } from 'react-native-safe-area-context'
import Routes from './src/routes'
import colors from './src/constants/colors'
import { Provider as ReduxProvider } from 'react-redux'
import store from './src/stores'
import { navigationRef } from './src/helpers/root-navigation'

const appTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    primary: colors.primary,
    secondary: colors.secondary,
    background: colors.background
  }
}

export default function App() {
  return (
    <ReduxProvider store={store}>
      <SafeAreaProvider>
        <StatusBar style="auto" />
        <NavigationContainer theme={appTheme} ref={navigationRef}>
          <SafeAreaView style={{ flex: 1 }}>
            <Routes />
          </SafeAreaView>
        </NavigationContainer>
      </SafeAreaProvider>
    </ReduxProvider>
  )
}
