import { StyleSheet, View, ViewProps, ViewStyle } from 'react-native'

interface Props extends ViewProps {
  gap?: number
  alignItems?: 'baseline' | 'center' | 'flex-end' | 'flex-start' | 'stretch'
  justifyContent?: 'center' | 'flex-end' | 'flex-start' | 'space-around' | 'space-between' | 'space-evenly'
  horizontal?: boolean
}

export default function Stack(props: Props) {
  const gapStyle: ViewStyle | null = props.gap ? { gap: props.gap } : null
  const alignItemsStyle: ViewStyle | null = props.alignItems ? { alignItems: props.alignItems } : null
  const justifyContentStyle: ViewStyle | null = props.justifyContent ? { justifyContent: props.justifyContent } : null
  const horizontalStyle: ViewStyle | null = props.horizontal ? { flexDirection: 'row' } : null
  return (
    <View style={[styles.stack, gapStyle, alignItemsStyle, justifyContentStyle, horizontalStyle]} {...props}>
      {props.children}
    </View>
  )
}

const styles = StyleSheet.create({
  stack: {
    display: 'flex'
  }
})
